import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from '../Pages/Home';
import InstalareIarba from '../Pages/InstalareIarba';
import InstalareTartan from '../Pages/InstalareTartan';
import Proiectare from '../Pages/Proiectare';
import Echipament from '../Pages/Echipament';
import Contacte from '../Pages/Contacte';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../img/logo.webp';
import enImg from '../img/en.webp';
import roImg from '../img/ro.webp';
import ruImg from '../img/ru.webp';
import uaImg from '../img/ua.webp';
import "@fontsource/poppins/300.css";
import { useTranslation } from 'react-i18next';

window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    if (document.body.scrollTop > 5 || document.documentElement.scrollTop > 80) {
        document.getElementById("navbar").style.padding = "0px 0px";
        document.getElementById("logo").style.width = "60px";

    } else {
        document.getElementById("navbar").style.padding = "10px 0px";
        document.getElementById("logo").style.width = "80px";

    }
}

export default function BasicExample() {
    const { t, i18n } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);

    }
    return (
        <Router>
            <Navbar id='navbar' collapseOnSelect expand="lg" className="bg-my text-center fixed-top">
                <Container>
                    <Navbar.Brand href="/">
                        <img id='logo' src={logo} height="80" width="80" className="d-inline-block align-center img-fluid" alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto mlink m-auto ">
                            <Nav.Link eventKey='1' class="m-auto px-4 my-3 " as={Link} to="/">{t('menu.home')}</Nav.Link>
                            <NavDropdown title={t('menu.works1')} id="basic-nav-dropdown" className="me-auto mlink m-auto">
                                <NavDropdown.Item eventKey='2' as={Link} to="/InstalareIarba">{t('menu.works2')} </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='3' as={Link} to="/InstalareTartan">{t('menu.works3')} </NavDropdown.Item>
                            </NavDropdown>
                            <div className='nowr'><Nav.Link eventKey='4' class="mp m-auto px-4 my-3" as={Link} to="/Proiectare">{t('menu.works4')} </Nav.Link></div>
                            <div className='nowr'><Nav.Link eventKey='5' class="mp m-auto px-4 my-3" as={Link} to="/Echipament">{t('menu.works5')} </Nav.Link></div>

                            <Nav.Link eventKey='6' class="mp m-auto px-4 my-3" as={Link} to="/Contacte">{t('menu.works6')} </Nav.Link>

                            <NavDropdown title={t('menu.language')} id="basic-nav-dropdown" className="menuButton  mx-3">
                                <NavDropdown.Item eventKey='5' class='p-0' onClick={() => changeLanguage("en")}>
                                    <img
                                        src={enImg}
                                        height="30"
                                        width="30"
                                        className="d-inline-block align-center btnLng"
                                        alt="EN"
                                    />EN

                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='6' class='p-0' onClick={() => changeLanguage("ro")}>
                                    <img
                                        src={roImg}
                                        height="30"
                                        width="30"
                                        className="d-inline-block align-center btnLng"
                                        alt="RO"
                                    />RO

                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='7' class='p-0' onClick={() => changeLanguage("ua")}>
                                    <img
                                        src={uaImg}
                                        height="30"
                                        width="30"
                                        className="d-inline-block align-center btnLng"
                                        alt="UA"
                                    />UA

                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item eventKey='8' class='p-0' onClick={() => changeLanguage("ru")}>
                                    <img
                                        src={ruImg}
                                        height="30"
                                        width="30"
                                        className="d-inline-block align-center btnLng"
                                        alt="RU"
                                    />RU

                                </NavDropdown.Item>

                            </NavDropdown>

                        </Nav>
                    </Navbar.Collapse>
                    {/* <div class='row mlink'>
                        <div class='d-none d-xl-block col-xl-12 '>
                            <p>(+373) 787 53 333</p>
                            <a href="mailto:info@geosport.md">info@geosport.md</a>
                        </div>
                    </div> */}
                </Container>
            </Navbar>

            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/InstalareIarba' element={<InstalareIarba />} />
                <Route path='/InstalareTartan' element={<InstalareTartan />} />
                <Route path='/Proiectare' element={<Proiectare />} />
                <Route path='/Echipament' element={<Echipament />} />
                <Route path='/Contacte' element={<Contacte />} />
            </Routes>
        </Router>

    );
}

