import React from 'react';
import Container from 'react-bootstrap/Container';
import img5 from '../img/lf/h3.1.webp';
import img6 from '../img/lf/h3.2.webp';
import img1 from '../img/lf/h1.1.webp';
import img2 from '../img/lf/h1.2.webp';
import img3 from '../img/lf/h2.1.webp';
import img4 from '../img/lf/h2.2.webp';
import img7 from '../img/lf/h4.1.webp';
import img8 from '../img/lf/h4.2.webp';
import img9 from '../img/lf/v1.webp';
import img10 from '../img/lf/v2.webp';
import img11 from '../img/lf/v3.webp';
import img12 from '../img/lf/v4.webp';
import img13 from '../img/lf/h5.1.webp';
import img14 from '../img/lf/h5.2.webp';
import img15 from '../img/lf/h6.1.webp';
import img16 from '../img/lf/h6.2.webp';
import img17 from '../img/lf/inv1.webp';
import img18 from '../img/lf/inv2.webp';
import img19 from '../img/lf/inh1.webp';
import img20 from '../img/lf/inh2.webp';
import { useTranslation } from 'react-i18next';


export default function InstalareIarbaExemple() {
    const { t } = useTranslation();
    return (
        <Container>
            <div class='row  mbg pb-3 text-center'>
                <h4>{t('iiarba.c1')}</h4>
            </div>
            {/* ---------- 1 H ---------- */}
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img5} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img6} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
            {/* ---------- 2 H ---------- */}
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img1} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img2} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
            {/* ---------- 1 V ---------- */}
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={667} src={img9} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={667} src={img10} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
            {/* ---------- 3 H ---------- */}
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img3} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img4} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img7} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img8} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={667} src={img11} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={667} src={img12} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img13} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={317} src={img14} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={521} height={326} src={img15} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={521} height={326} src={img16} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>

            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={667} src={img17} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={515} height={667} src={img18} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={521} height={326} src={img19} class="py-3 img-fluid" alt="instalation-example" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 '>
                    <img width={521} height={326} src={img20} class="py-3 img-fluid" alt="instalation-example" />
                </div>
            </div>
        </Container>
    );
}
