import React from 'react';
import { Container } from 'react-bootstrap';
import phone from '../img/phone.png';
import envelope from '../img/envelope.png';
import { useTranslation } from 'react-i18next';

export default function Contacte1() {
    const { t } = useTranslation();
    return (
        <Container class='fluid' className='bck'>
            <div class='row ptop2 pb-5 '>
                <h2 class=' text-center'>{t('menu.works6')}</h2>
            </div>
            <div class='row pb-5 px-3'>

                <div class='col-lg-5 col-md-6 text-center m-3 border'>
                    <h3>Moldova</h3>
                    <div className='nowr1 center'>
                        <img width={20} height={20} src={phone} class="img-fluid" alt="phone" />
                        <p class='mp p2'> (+373) 787 53 333</p>
                    </div>
                    <div className='nowr1'>
                        <img width={28} height={20} src={envelope} class="img-fluid" alt="phone" />
                        <p class='mp p2'><a href='mailto:moldova@geosportgroup.com'>moldova@geosportgroup.com</a></p>
                    </div>
                </div>

                <div class='col-lg-5 col-md-6 text-center m-3 border'>
                    <h3>Romania</h3>
                    <div className='nowr1'>
                        <img width={20} height={20} src={phone} class="img-fluid" alt="phone" />
                        <p class='mp p2'> (+40) 732 934 736</p>
                    </div>
                    <div className='nowr1'>
                        <img width={28} height={20} src={envelope} class="img-fluid" alt="phone" />
                        <p class='mp p2'><a href='mailto:romania@geosportgroup.com'>romania@geosportgroup.com</a></p>
                    </div>
                </div>
                <div class='col-lg-5 col-md-6 text-center m-3 border'>
                    <h3>Ucraina</h3>
                    <div className='nowr1'>
                        

                    </div>
                    <div className='nowr1'>
                        <img width={28} height={20} src={envelope} class="img-fluid" alt="phone" />
                        <p class='mp p2'><a href='mailto:ukraine@geosportgroup.com'>ukraine@geosportgroup.com</a></p>
                    </div>
                </div>

            </div>
            <div class='row dist'></div>
        </Container >
    );
}
