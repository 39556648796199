import React from 'react';
import { Container } from 'react-bootstrap';

import img1 from '../img/edelgrass.webp';
import img2 from '../img/smg.webp';
import img3 from '../img/redexim.webp';
import img4 from '../img/stockmeier.webp';
import img5 from '../img/harke.png';
import img6 from '../img/ruse.webp';
import { useTranslation } from 'react-i18next';

export default function Home4() {
    const { t, } = useTranslation();
    return (
        <Container>
            <div class='row  mbg pt-5'>
                <div class='col-lg-10 col-md-12'>
                    <h1 class='text-center py-3 '>{t('home.parteneri1')}</h1>
                </div>
            </div>
            <div class='row  mbg mb-5 pt-3 bckw'>
                <div class='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-5 nowr1'>
                    <img width={608} height={90} src={img1} class="par1 img-fluid" alt="partners" />
                </div>
                <div class='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-5 nowr1'>
                    <img width={600} height={419} src={img2} class="par1 img-fluid" alt="partners" />
                </div>
                <div class='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-5 nowr1'>
                    <img width={500} height={163} src={img3} class="par1 img-fluid" alt="partners" />
                </div>
                <div class='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-5 nowr1'>
                    <img width={600} height={333} src={img4} class="par1 img-fluid" alt="partners" />
                </div>
                <div class='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-5 nowr1'>
                    <img width={200} height={250} src={img5} class="par1 img-fluid" alt="partners" />
                </div>
                <div class='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-5 nowr1'>
                    <img width={600} height={600} src={img6} class="par1 img-fluid" alt="partners" />
                </div>



            </div>
        </Container>
    );
}
