import React from 'react';
import "@fontsource/poppins/200.css";
import Home1 from '../Components/Home1';
import Home2 from '../Components/Home2';
import Home2a from '../Components/Home2a';
import Home3 from '../Components/Home3';
import Home4 from '../Components/Home4';
import Bottom from '../Components/Bottom';
import GoToTop from '../Components/GoToTop';
import ScrollToTop from "react-scroll-to-top";

export default function Home() {

        return (
            <>
                <Home1 />
                <Home2 />
                <Home2a />
                <Home3 />
                <Home4 />
                <Bottom />
                <GoToTop />
                <ScrollToTop />
            </>
        );
    }
