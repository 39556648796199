import React from 'react';
import Container from 'react-bootstrap/Container';
import ParculCriuleni from '../Components/ParculCriuleni';
import DubasariiVechi from './DubasariiVechi';
import GMeniuc from './GMeniuc';
import Hyperion from './Hyperion';
import LiceulSportiv2 from './LiceulSportiv2';
import OrizontBuiucani from './OrizontBuiucani';
import OrizontCiocana from './OrizontCiocana';
import { useTranslation } from 'react-i18next';

export default function ProiectareExemple() {
    const { t } = useTranslation();
        return (
            <Container>
                <div class='row  mbg pb-2 text-center'>
                    <h3>{t('proiectare.a11')}</h3>
                </div>
                <hr className='m-auto' />
                <ParculCriuleni />
                <hr className='m-auto' />
                <OrizontCiocana />
                <hr className='m-auto' />
                <OrizontBuiucani />
                <hr className='m-auto' />
                <Hyperion />
                <hr className='m-auto' />
                <LiceulSportiv2 />
                <hr className='m-auto' />
                <GMeniuc />
                <hr className='m-auto' />
                <DubasariiVechi />
            </Container>
        );
    }
