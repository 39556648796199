import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import logo from '../img/logo.webp';
import phone from '../img/phone.png';
import envelope from '../img/envelope.png';
import { useTranslation } from 'react-i18next';

export default function Bottom() {
    const { t } = useTranslation();
    return (
        <Container fluid>
            <div class='row bg-my py-3'>
                <div class='col-xl-2 col-lg-5 col-md-5 col-sm-10 m-auto text-center '>
                    <img src={logo} height="80" width="80" className="d-inline-block align-center" alt="Logo" />
                    <h5 class='py-3 '>Geosport Group</h5>
                </div>
                <div class='d-none col-xl-2 d-lg-block col-lg-5 col-xl-3 d-lg-block d-xl-block mblink m-auto'>
                    <Nav.Link as={Link} to="/InstalareIarba"><u>{t('menu.works2')}</u></Nav.Link>
                    <Nav.Link as={Link} to="/InstalareTartan"><u>{t('menu.works3')}</u></Nav.Link>
                    <Nav.Link as={Link} to="/Proiectare"><u>{t('menu.works4')}</u></Nav.Link>
                    <Nav.Link as={Link} to="/Echipament"><u>{t('menu.works5')}</u></Nav.Link>
                </div>
                <div class=' d-none d-lg-block d-xl-none col-lg-10 dist2'>

                </div>
                <div class='d-none d-lg-block d-xl-block d-md-block d-sm-block d-xl-block d-xl-block col-xl-7 col-lg-10 col-md-12 col-sm-12 mblink  text-center'>
                    <p class='font-size: larger'><b>{t('menu.works6')}:</b></p>
                    <div className='row'>
                        <div class='col-xl-4 col-lg-4 col-md-3 col-sm-2 col-2 mblink m-auto text-end'>
                            <p class='p1'>{t('home.contactsMoldova')}</p>
                            <p class='p1'>{t('home.contactsRomania')}</p>
                            <p class='p1'>{t('home.contactsUkraine')}</p>
                        </div>
                        <div class='col-xl-8 col-lg-8 col-md-8 col-sm-9 col-9 mblink m-auto text-start '>
                            <div className='nowr1'>
                                <img width={20} height={20} src={phone} class="img-fluid" alt="phone" />
                                <p className='p1' >(+373) 787 53 333</p>
                                <img width={28} height={20} src={envelope} class="img-fluid ml" alt="phone" />
                                <p className='p1'><a href='mailto:moldova@geosportgroup.com'> moldova@geosportgroup.com</a></p>
                            </div>
                            <div className='nowr1'>
                                <img width={20} height={20} src={phone} class="img-fluid" alt="phone" />
                                <p className='p1'>(+40) 732 934 736</p>
                                <img width={28} height={20} src={envelope} class="img-fluid ml" alt="phone" />
                                <p className='p1'><a href='mailto:romania@geosportgroup.com'> romania@geosportgroup.com</a></p>
                            </div>
                            <div className='nowr1'>
                                
                                
                                <img width={28} height={20} src={envelope} class="img-fluid ml" alt="phone" />
                                <p className='p1'><a href='mailto:ukraine@geosportgroup.com'> ukraine@geosportgroup.com</a></p>
                            </div>
                        </div>
                        {/*<img width={20} height={20} src={phone} class="img-fluid" alt="phone" />
                         <p className='p1'>(+380) 977 077 014</p>
                        
                        
                        <div class='col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 mblink m-auto text-start'>

                            <div className='nowr1'>
                                <img width={28} height={20} src={envelope} class="img-fluid" alt="phone" />
                                <p className='p1'><a href='mailto:romania@geosportgroup.comm'> romania@geosportgroup.com</a></p>
                            </div>
                            <div className='nowr1'>
                                <img width={28} height={20} src={envelope} class="img-fluid" alt="phone" />
                                <p className='p1'><a href='mailto:ukraine@geosportgroup.com'> ukraine@geosportgroup.com</a></p>
                            </div>
                        </div> */}
                    </div>
                </div>



                <div class='d-block  d-sm-none  col-xl-7 col-lg-10 col-md-12 col-sm-12 mblink  text-center'>
                    <p class='font-size: larger'><b>{t('menu.works6')}:</b></p>
                    <div className='row'>
                        <div class='col-10 mblink m-auto '>
                            <p class=''>{t('home.contactsMoldova')}</p>
                            <p>(+373) 787 53 333</p>
                            <p ><a href='mailto:moldova@geosportgroup.com'>e-mail: moldova@geosportgroup.com</a></p>
                            <p class=''>{t('home.contactsRomania')}</p>
                            <p>(+40) 732 934 736</p>
                            <p ><a href='mailto:romania@geosportgroup.comm'>e-mail: romania@geosportgroup.com</a></p>
                            <p class=''>{t('home.contactsUkraine')}</p>
                            <p>(+380) 977 077 014</p>
                            <p ><a href='mailto:ukraine@geosportgroup.com'>e-mail: ukraine@geosportgroup.com</a></p>
                        </div>


                    </div>
                </div>

            </div>
            {/* <a href="mailto:info@geosport.md">info@geosport.md</a> */}
        </Container>
    );
}

