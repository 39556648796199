import React from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/t1.webp';
import img2 from '../img/t2.webp';
import img3 from '../img/t3.webp';
import img4 from '../img/t4.webp';
import img5 from '../img/t5.webp';
import img6 from '../img/t6.webp';
import { useTranslation } from 'react-i18next';

export default function InstalareTartan1() {
    const { t } = useTranslation();
    return (
        <Container>
            <div class='row  mbg ptop2 pb-5 '>
                <h2 class=' text-center'>{t('itartan.a1')}</h2>
                <div class='col-lg-5 col-md-6'>
                    <hr />
                    <p>{t('itartan.a2')}</p>
                    <p>{t('itartan.a3')}</p>
                    <hr />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 px-5 pt-3'>
                    <img width={400} height={400} src={img1} class="py-3 img-fluid" alt="EPDM" />
                </div>
            </div>
            <div class='row  mbg pb-5 text-center'>
                <div class='col-lg-10 col-md-10'>
                    <h5>{t('itartan.a4')}</h5>
                </div>
                <div class='col-lg-10 col-md-10'>
                    <img width={487} height={399} src={img2} class="py-3 img-fluid" alt="sportChamp" />
                </div>
            </div>

            <hr />
            <div class='row  mbg pb-5 '>
                <h4 class=' text-center'>{t('itartan.a5')}</h4>
                <div class='col-lg-5 col-md-6'>
                    <hr />
                    <p>{t('itartan.a6')}</p>
                    <hr />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10  px-5'>
                    <img width={300} height={254} src={img3} class="py-3 img-fluid" alt="EPDM mix" />
                </div>
            </div>
            <div class='row  mbg  pb-5 '>
                <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                    <img width={400} height={220} src={img4} class="py-3 img-fluid" alt="EPDM aplication" />
                </div>
                <div class='col-lg-5 col-md-6'>
                    <hr />
                    <p>{t('itartan.a8')}</p>
                    <hr />
                </div>
            </div>

            <div class='row  mbg  text-center'>
                <div class='col-lg-5 col-md-6 col-sm-10 text-end'>
                    <img width={666} height={499} src={img5} class="py-3 img-fluid" alt="SMG application" />
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 text-start'>
                    <img width={666} height={499} src={img6} class="py-3 img-fluid" alt="SMG application" />
                </div>
            </div>
            <hr />
        </Container>
    );
}
