import React from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/pf/dubasari1.webp';
import img2 from '../img/pf/dubasari2.webp';
import img3 from '../img/pf/dubasari3.webp';
import img4 from '../img/pf/dubasari4.webp';
import { useTranslation } from 'react-i18next';

export default function DubasariiVechi() {
    const { t } = useTranslation();
        return (

            <Container>
                <div class='row  text-center mbg pt-4 '>
                    <h4>{t('proiectare.Dubasari')}</h4>
                </div>
                <div class='row  mbg mvid'>
                    <iframe class="mif" title='DubasariiVechi' src="https://www.youtube.com/embed/a042PC-Ijig?rel=0&showinfo=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class='row  mbg pt-3'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img1}
                            class="pb-3 img-fluid"
                            alt="DubasariiVechi"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img2}
                            class="pb-3 img-fluid"
                            alt="DubasariiVechi"
                        />
                    </div>
                </div>

                <div class='row  mbg pb-3'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img3}
                            class="pb-3 img-fluid"
                            alt="DubasariiVechi"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img4}
                            class="pb-3 img-fluid"
                            alt="DubasariiVechi"
                        />
                    </div>
                </div>
            </Container >
        );
    }
