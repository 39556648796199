import React from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/p1.webp';
import img2 from '../img/p2.webp';
import img3 from '../img/p3.webp';
import img4 from '../img/p4.webp';
import img5 from '../img/p5.webp';
import { useTranslation } from 'react-i18next';

export default function Proiectare1() {
    const { t } = useTranslation();
        return (
            <Container>
                <div class='row  mbg ptop2 pb-5 '>
                    <h2 class=' text-center'>{t('proiectare.a1')}</h2>
                    <div class='col-lg-5 col-md-6'>
                        <hr />
                        <p>{t('proiectare.a2')}</p>
                        <hr />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                        <img width={570} height={480} src={img1} class="py-3 img-fluid" alt="grass" />
                    </div>
                </div>

                <div class='row  mbg pb-5 flex-column-reverse flex-md-row'>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                        <img width={533} height={179} src={img2} class="pb-3  img-fluid" alt="grass" />
                        <img width={533} height={179} src={img3} class=" img-fluid" alt="grass" />
                    </div>
                    <div class='col-lg-5 col-md-6'>
                        <p class='text-center'><b>{t('proiectare.a3')}</b></p>
                        <p>{t('proiectare.a4')} </p>
                        <p>{t('proiectare.a5')}</p>
                        <p>{t('proiectare.a6')} </p>
                    </div>
                </div>
                <div class='row  mbg pb-5 '>
                    <div class='col-lg-5 col-md-6'>
                        <p class='text-center'><b>{t('proiectare.a7')}</b></p>
                        <p>{t('proiectare.a8')}</p>
                        <p>{t('proiectare.a9')}</p>
                        <p>{t('proiectare.a10')} </p>
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 px-5'>
                        <img width={533} height={195} src={img4} class="pb-3 img-fluid" alt="grass" />
                        <img width={533} height={195} src={img5} class=" img-fluid" alt="grass" />
                    </div>
                </div>
                <hr />
            </Container>
        );
    }
