import React from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../img/ec/echipament1.webp';
import img2 from '../img/ec/echipament2.webp';
import img3 from '../img/ec/echipament3.webp';
import img4 from '../img/ec/echipament4.webp';
import img5 from '../img/ec/echipament5.webp';
import img6 from '../img/ec/echipament6.webp';
import img7 from '../img/ec/echipament7.webp';
import img8 from '../img/ec/echipament8.webp';
import img9 from '../img/ec/en1.webp';
import img10 from '../img/ec/en2.webp';
import img11 from '../img/ec/en3.webp';
import img12 from '../img/ec/en4.webp';
import img13 from '../img/ec/en5.webp';
import img14 from '../img/ec/en6.webp';
import img15 from '../img/ec/en7.webp';
import img16 from '../img/ec/en8.webp';
import img17 from '../img/tables/t1.webp';
import img18 from '../img/tables/t2.webp';
import img19 from '../img/tables/t3.webp';
import img20 from '../img/tables/t4.webp';
import { useTranslation } from 'react-i18next';

export default function Echipament() {
    const { t } = useTranslation();
        return (
            <Container>
                <div class='row  mbg ptop2 pb-5 '>
                    <h2 class=' text-center'>{t('echipament.a1')}</h2>
                    <p class='pt-3 text-center'>{t('echipament.a2')}</p>
                </div>
                <div class='row  mbg pb-5 mw px-3'>
                    <ul>
                        <li>{t('echipament.a3')}</li>
                        <li>{t('echipament.a4')}</li>
                        <li>{t('echipament.a5')}</li>
                        <li>{t('echipament.a6')}</li>
                        <li>{t('echipament.a7')}</li>
                    </ul>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img17} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img18} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img19} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img20} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>


                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img1} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img2} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img3} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img4} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={687} src={img5} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={687} src={img6} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img7} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img8} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>

                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img9} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img10} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>
                
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img11} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img12} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>
                
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img13} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img14} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>
                <div class='row  mbg  text-center'>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img15} class="py-3 img-fluid" alt="echipament" />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10 '>
                        <img width={515} height={317} src={img16} class="py-3 img-fluid" alt="echiapment" />
                    </div>
                </div>
            </Container>
        );
    }
