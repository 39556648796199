import React from 'react';
import Container from 'react-bootstrap/Container';
import img1 from '../img/pf/LiceulSportiv21.webp';
import img2 from '../img/pf/LiceulSportiv22.webp';
import img3 from '../img/pf/LiceulSportiv23.webp';
import img4 from '../img/pf/LiceulSportiv24.webp';
import { useTranslation } from 'react-i18next';

export default function LiceulSportiv2() {
    const { t } = useTranslation();
        return (
            <Container>
                <div class='row  text-center mbg pt-4 '>
                    <h4>{t('proiectare.LSportiv')}</h4>
                </div>
                <div class='row  mbg mvid'>
                    <iframe class="mif" title='Liceul Sportiv' src="https://www.youtube.com/embed/H0rhuI1lbxE?rel=0&showinfo=0"></iframe>
                    {/* <iframe  url="https://www.youtube.com/embed/PV5jiLTdpzk" */}
                </div>
                <div class='row  mbg pt-3'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img1}
                            class="pb-3 img-fluid"
                            alt="LiceulSportiv2"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img2}
                            class="pb-3 img-fluid"
                            alt="LiceulSportiv2"
                        />
                    </div>
                </div>

                <div class='row  mbg pb-3'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img3}
                            class="pb-3 img-fluid"
                            alt="LiceulSportiv2"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={550}
                            height={309}
                            src={img4}
                            class="pb-3 img-fluid"
                            alt="LiceulSportiv2"
                        />
                    </div>
                </div>
            </Container >
        );
    }
