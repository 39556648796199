import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

export default function Home2a() {
    const { t } = useTranslation();
        return (
            <Container> 
                <div class='row mbg pt-5'>
                    <div class='col-lg-12 col-md-12 col-sm-12 text-center'>
                    <iframe className='video' width="660" height="370" src={t('home.video')} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>

                    </iframe>
                    </div>
                </div>
               
            </Container>
        );
    }
